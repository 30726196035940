import React from "react";
import SubHeading from "../../components/SubHeading/SubHeading";
import img from "../../constants/images";
import "./Header.css";
import { motion } from "framer-motion"
import cafe from "../../assets/cafe.png";
import bgImg from "../../assets/main-bg.png";
import { ScrollParallax } from "react-just-parallax";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

const goToMenu=()=>{
	document.getElementById("menu").scrollIntoView();

}
const sendMessage = ()=>{
        window?.open('https://wa.me/556139633163?text=Olá, gostaria de fazer um pedido', '_blank')?.focus();  
}
const Header = () => (
	<div
	id="Home"
	style={{ backgroundImage: `url(${bgImg})` }}
  >

	<div className="app__header app__wrapper section__padding" >
		{/* <div className="app__wrapper_info">
			<h1 className="app__header-h1">Cafe Plaza Mayor</h1>
			<p className="p__opensans" style={{ margin: "2rem 0rem" }}>
				Explore novos sabores
			</p>
			<button className="custom__button"> Menu</button>
		</div> */}
		<motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 4 }}
            >
				          <ScrollParallax>

              <img
                src={cafe}
                alt="Cafe Plaza"
                title="Cafe Plaza"
                placeholder="tracedSVG"
                className="z-[5] max-w-screen-md hero-coffe-img hero-animation"
              />
			            </ScrollParallax>

            </motion.div>

            <motion.div
              className="z-[5] text-[#222] font-normal uppercase tracking-[.2em] drop-shadow"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2, duration: 3 }}
            >
				{/* <button onClick={goToMenu} className="custom__button">Menu</button> */}
				</motion.div>
      <FloatingWhatsApp allowEsc={true} avatar={cafe} notification={false} onClick={sendMessage} chatMessage="Boa tarde, tudo bem?" statusMessage={''} phoneNumber={"556139633163"}  accountName={"Café Plaza"} />

	</div>
	</div>
);

export default Header;
