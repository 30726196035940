import React from "react";
import { MenuItem, SubHeading } from "../../components";
import { data } from "../../constants";
import img from "../../constants/images";
import "./SpecialMenu.css";
import bgImg from "../../assets/main-bg.png";

const SpecialMenu = () => (
	<div
	id="menu"
	style={{ backgroundImage: `url(${bgImg})` }}
  >
	<div className="app__menu flex__center section__padding" id="menu">
		<div className="app__menu-heading">
			<SubHeading title="Café Plaza" />
			<h1 className="headtext__cormorant">Cardápio</h1>
		</div>
		<div className="app__menu-menuList flex__center">
			<div className="app__menu-menuList_wines flex__center">
				<p className="app__menu-menuList_type">Salgados</p>
				<div className="app__menu-menuList_items">
					{data[0].map((wine, index) => {
						return (
							<MenuItem
								key={wine.title + index}
								title={wine.title}
								tag={wine.tags}
								price={wine.price}
							/>
						);
					})}
				</div>
			</div>
			<div className="app__menu-menuList_wines flex__center">
				<p className="app__menu-menuList_type">Especialidades</p>
				<div className="app__menu-menuList_items">
					{data[2].map((wine, index) => {
						return (
							<MenuItem
								key={wine.title + index}
								title={wine.title}
								tag={wine.tags}
								price={wine.price}
							/>
						);
					})}
				</div>
			</div>
			<div className="app__menu-menuList_wines flex__center">
				<p className="app__menu-menuList_type">Bebidas Quentes</p>
				<div className="app__menu-menuList_items">
					{data[3].map((wine, index) => {
						return (
							<MenuItem
								key={wine.title + index}
								title={wine.title}
								tag={wine.tags}
								price={wine.price}
							/>
						);
					})}
				</div>
			</div>
			<div className="app__menu-menuList_wines flex__center">
				<p className="app__menu-menuList_type">Tapiocas</p>
				<div className="app__menu-menuList_items">
					{data[1].map((wine, index) => {
						return (
							<MenuItem
								key={wine.title + index}
								title={wine.title}
								tag={wine.tags}
								price={wine.price}
							/>
						);
					})}
				</div>
			</div>
			<div className="app__menu-menuList_wines flex__center">
				<p className="app__menu-menuList_type">Omeletes</p>
				<div className="app__menu-menuList_items">
					{data[4].map((wine, index) => {
						return (
							<MenuItem
								key={wine.title + index}
								title={wine.title}
								tag={wine.tags}
								price={wine.price}
							/>
						);
					})}
				</div>
			</div>
			
		</div>
		</div>
		
		
	</div>
);

export default SpecialMenu;
