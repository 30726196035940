import React from "react";

import "./App.css";
import { Navbar } from "./components";
import {
	AboutUs,
	Chef,
	FindUs,
	Footer,
	Gallery,
	Header,
	SpecialMenu,
} from "./container";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzkpw6L5pm3i7Z1xFqabgeoCvQ-Xsvpac",
  authDomain: "cafe-b0ba4.firebaseapp.com",
  projectId: "cafe-b0ba4",
  storageBucket: "cafe-b0ba4.appspot.com",
  messagingSenderId: "283157067846",
  appId: "1:283157067846:web:c15b3dd0387a752b9e3247",
  measurementId: "G-NDN5FHVN01"
};
const getLocation=(analytics)=>{
		// Check if geolocation is supported by the browser
		if ("geolocation" in navigator) {
			// Prompt user for permission to access their location
			navigator.geolocation.watchPosition(
			  // Success callback function
			  function(position) {
				// Get the user's latitude and longitude coordinates
				const lat = position.coords.latitude;
				const lng = position.coords.longitude;
logEvent(analytics, 'notification_received');
		  
				// Update the map with the user's new location
				console.log(`Latitude: ${lat}, longitude: ${lng}`);
			  },
			  // Error callback function
			  function(error) {
				// Handle errors, e.g. user denied location sharing permissions
				console.error("Error getting user location:", error);
			  }
			);
		  } else {
			// Geolocation is not supported by the browser
			console.error("Geolocation is not supported by this browser.");
		  }
}



const App = () => {



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
getLocation(analytics)





	return (
	<div>
		{/* <Navbar /> */}
		<Header />
		{/* <AboutUs /> */}
		<SpecialMenu />
		{/* <Chef /> */}
		{/* <Gallery /> */}
		{/* <FindUs /> */}
		{/* <Footer /> */}
	</div>
	);
};


export default App;
