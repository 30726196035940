const salgados = [
	{
		title: "Coxinha de Frango",
		price: "R$8",
		tags: "",
	},
	{
		title: "Empada de frango",
		price: "R$10",
		tags: "",
	},
	{
		title: "Torta de frango",
		price: "R$10",
		tags: "com catupiry",
	},
	{
		title: "Croassaint",
		price: "R$10",
	},
	{
		title: "Pão da vovó",
		price: "R$9",
	},
	{
		title: "Kibe",
		price: "R$8",
	},
	{
		title: "Esfirra",
		price: "R$9",
	},
	{
		title: "Enroladinho de Presunto e queijo",
		price: "R$9",
	},
	{
		title: "Baurú",
		price: "R$9",
		tags: "presunto, queijo e tomate",
	},
	{
		title: "Pão Pizza",
		price: "R$9",
	},
	{
		title: "Enroladinho de queijo",
		price: "R$9",
	},
	{
		title: "Enroladinho de salsicha",
		price: "R$9",
		tags:"assado"
	},
	{
		title: "Enroladinho de salsicha",
		price: "R$8",
		tags:"frito"
	},
];

const tapiocas = [
	{
		title: "Simples",
		price: "R$9",
		tags: "Manteiga",
	},
	{
		title: "Presunto, Queijo e tomate",
		price: "R$13",
	},
	{
		title: "Carne Seca, Tomate e Queijo",
		price: "R$19",
	},
	{
		title: "Frango com Requeijão",
		price: "R$16",
	},
	{
		title: "Frango, Queijo e tomate",
		price: "R$16",
	},
	{
		title: "Peito de Peru, queijo, tomate e orégano",
		price: "R$20",
	},
	{
		title: "Ovo",
		price: "R$12",
	},
];


const omeletes = [
	{
		title: "2 ovos",
		price: "R$9",
		tags: "Manteiga e sal",
	},
	{
		title: "2 ovos, presunto, queijo, tomate",
		price: "R$13",
	},
	{
		title: "2 ovos, carne seca e queijo",
		price: "R$20",
	},
	{
		title: "2 ovos, frango desfiado e queijo",
		price: "R$16",
	},
	{
		title: "3 ovos, presunto, queijo, tomate",
		price: "R$16",
	},
	{
		title: "3 ovos, carne seca e queijo",
		price: "R$23",
	},
];


const especialidades = [
	{
		title: "Açaí",
		price: "R$30",
		tags: "com banana e granola",
	},
	{
		title: "Sanduiche Natural",
		price: "R$16",
		tags: "(Pão integral, peito de Frango ou de peru, cenoura, re tomate, queijo e orégano).",
	},
	{
		title: "Salada de frutas",
		price: "R$10",
		tags: "",
	},
	{
		title: "Sucos",
		price: "R$10",
		tags: "Naturais e Polpas",
	},
	{
		title: "Vitamina de fruta",
		price: "R$12",
	},
];
const hotDrinks = [
	{
		title: "Café da casa",
		price: "R$4,5",
		tags: "",
	},
	{
		title: "Café expresso",
		price: "R$7,5",
		tags: "",
	},
	{
		title: "Café expresso duplo",
		price: "R$14",
		tags: "",
	},
	{
		title: "Chá quente",
		price: "R$7,5",
		tags: "",
	},
	{
		title: "Nescafé café",
		price: "R$7,5",
		tags: "",
	},
	{
		title: "Nescafé café com leite",
		price: "R$11",
		tags: "",
	},
	{
		title: "Nescafé Mocaccino",
		price: "R$11",
		tags: "",
	},
	{
		title: "Nescafé Alpino",
		price: "R$11",
		tags: "",
	},
	{
		title: "Nescafé Cappuccino",
		price: "R$11",
		tags: "",
	},
	{
		title: "Nescafé Cappuccino Alpino",
		price: "R$11",
		tags: "",
	},
];

const data = [salgados, tapiocas,especialidades,hotDrinks,omeletes];

export default data;
